<h6
  *ngIf="api_route == 'Invoice/readGRNReadyInvoiceList'"
  class="headh6 f-14 text_color d-flex justify-content-between"
>
  <div>Create GRN with Invoice</div>
  <button class="primary_btn f-12 pl-3 pr-3" (click)="grnWithPOdialog = true">
    Create GRN with PO
  </button>
</h6>
<div
  class="filters d-flex justify-content-end"
  [ngClass]="api_route == 'Invoice/readGRNReadyInvoiceList' ? '' : 'pt-2'"
>
  <div class="wild_search_all">
    <div class="filter_box">
      <label for="uni" class="f-12">
        Search Universal <br />
        <input
          [(ngModel)]="searchText"
          type="text"
          id="uni"
          class="textFilter f-13"
          [placeholder]="search_placeholder"
          (keyup)="universalSearch(searchText)"
        />
      </label>
    </div>
    <div class="calender_div">
      <p-calendar
        #datePicker
        [(ngModel)]="rangeDates"
        selectionMode="range"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder=""
        [readonlyInput]="true"
        [showIcon]="true"
        showButtonBar="true"
        (onClearClick)="clearDates()"
        (onSelect)="filterByDate(rangeDates)"
        inputId="range"
      ></p-calendar>
    </div>
  </div>
</div>

<div class="pt-1 mt-2">
  <app-exception-table
    [invoiceColumns]="ColumnsForGRN"
    [columnsToDisplay]="columnsToDisplay"
    [showPaginatorAllInvoice]="showPaginatorAllInvoice"
    [ColumnLength]="GRNTableColumnLength"
    (searchInvoiceData)="searchInvoiceDataV($event)"
    [columnsData]="columnsData"
    [searchText]="searchText"
    [pageId]="'exc_v'"
    [pageNumber]="pageNumber"
    (filterDataEmit)="filterEmit($event)"
    (paginationEvent)="paginate($event)"
  >
  </app-exception-table>

  <ngx-spinner
    bdColor="rgba(251, 251, 251, 0.8)"
    size="medium"
    color="#070900"
    [fullScreen]="false"
    type="ball-spin-clockwise"
  >
    <p style="color: rgb(0, 0, 0)"></p>
  </ngx-spinner>
</div>

<p-dialog
  header="Create GRN with Purchase Order"
  [(visible)]="grnWithPOdialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '35vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
>
  <form
    #PO_GRNForm="ngForm"
    (ngSubmit)="routeToGRN(PO_GRNForm.value)"
    class="d-flex flex-wrap ml-2"
    style="gap: 15px"
  >
    <!-- select PO Number -->
    <div class="w-100">
      <div class="box mr-2">
        <label class="f-13 mb-0">Enter PO Number</label><br />
        <p-autoComplete
          placeholder="Ex: Enter PO number and press Enter"
          (onSelect)="selectedPO($event)"
          [group]="false"
          [suggestions]="filteredPO"
          (completeMethod)="filterPOnumber($event)"
          (onKeyUp)="getPODetails($event.target.value, $event)"
          (onClick)="getPODetails($event.target.value, $event)"
          field="PODocumentID"
          [dropdown]="true"
          name="PONumber"
          [(ngModel)]="PONumber"
          required
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.PODocumentID }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
        <!-- <input type="text" class="form-control f-12" (keyup)="getPODetails($event.target.value,$event)" placeholder="Ex: RTC-PO-123456 and press Enter" name="PONumber" ngModel required> -->
      </div>
      <small class="f-12">Note: Please press "Enter"</small>
    </div>
    <div class="box mr-2">
      <label class="f-12 mb-0 z_index">Entity Name</label><br />
      <input
        type="text"
        class="form-control f-12"
        [(ngModel)]="EntityName"
        name="EntityName"
        required
        readonly
      />
      <!-- <p-autoComplete
        placeholder="Select Entity"
        (onSelect)="selectEntity($event)"
        [group]="false"
        [suggestions]="filteredEnt"
        (completeMethod)="filterEntity($event)"
        field="EntityName"
        [dropdown]="true"
        name="EntityName"
        [(ngModel)]="entityName"
        required
      >
      </p-autoComplete> -->
    </div>

    <!--Vendor Selection-->
    <div class="box mr-2">
      <label class="f-12 mb-0 z_index">VendorName</label><br />
      <!-- <p-autoComplete
        placeholder="Select vendor"
        (onSelect)="selectedVendor($event)"
        [group]="false"
        [suggestions]="filteredVendors"
        (completeMethod)="filterVendor($event)"
        field="VendorName"
        [dropdown]="true"
        name="vendor"
        ngModel
        required
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <span class="f-12">{{ group.VendorName }}</span>
          </div>
        </ng-template>
      </p-autoComplete> -->
      <input
        type="text"
        class="form-control f-12"
        [(ngModel)]="vendorName"
        name="vendor"
        required
        readonly
      />
    </div>

    <!-- select GRN Line -->
    <div style="display: none">
      <label class="f-13 mb-0">Select PO Line</label><br />
      <p-multiSelect
        [options]="poLineData"
        placeholder="line-description"
        filter="false"
        inputStyleClass="form-control"
        optionLabel="Name"
        [maxSelectedLabels]="3"
        [selectedItemsLabel]="'{0} items selected'"
        name="PO_GRN_Number_line"
        ngModel
        required
        [(ngModel)]="PO_GRN_Number_line"
      >
      </p-multiSelect>
    </div>
    <div class="w-100 d-flex justify-content-between mt-5 pt-2 footer">
      <div class="indication f-12">
        Create click will take you to creation page
      </div>
      <button
        class="primary_btn f-12"
        [disabled]="PO_GRNForm.invalid"
        [ngClass]="PO_GRNForm.invalid ? 'disable' : ''"
      >
        Create
      </button>
    </div>
  </form>
</p-dialog>
<p-toast></p-toast>
