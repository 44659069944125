<div class="layout">
  <nav
    class="desktop d-flex justify-content-between"
    [ngClass]="
    ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name) ? 'cenomiBg' : ''
    "
  >
    <div class="d-flex" style="gap: 64px">
      <div>
        <img
        *ngIf="dataStoreService?.configData?.client_name != 'AGI'"
          [src]="logoSrc"
          alt="Serina logo"
          [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'otherBrand'
              : 'Serina'
          "
        />
        <img
          *ngIf="dataStoreService?.configData?.client_name == 'AGI'"
          [src]="logoSrc"
          alt="Serina logo"
          class="AGI"
        />
      </div>
      <!-- <div  *ngIf="dataStoreService.configData.client_name != 'Cenomi'">
        <img src="assets/Serina Assets/new_theme/logo.png" alt="Serina logo" class="Serina" />
      </div> -->
      <div class="navLinks d-flex justify-content-between" *ngIf="isDesktop">
        <div
          class="nav_Link f-14"
          [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'dashboard'"
          routerLinkActive="active_cls"
          (click)="navClick()"
        >
          Dashboard
        </div>
        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'uploadInvoices'"
          routerLinkActive="active_cls"
          *ngIf="uploadPermissionBoolean"
          (click)="navClick()"
        >
          Upload
        </div>

        <div style="position: relative">
          <div
            class="nav_Link f-14"
            [ngClass]="{
              active_cls: router.url.includes('ExceptionManagement'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
            (click)="exceptionDrop()"
            [routerLink]="vendorInvoiceAccess && !serviceInvoiceAccess ? 'ExceptionManagement' : ((!vendorInvoiceAccess && serviceInvoiceAccess) ? 'ExceptionManagement/Service_ExceptionManagement':null)"
            *ngIf="excpetionPageAccess"
          >
            Exceptions
            <span
              class="material-icons more_pos"
              *ngIf="vendorInvoiceAccess && serviceInvoiceAccess && !isOpen"
            >
              expand_more
            </span>
            <span
              class="material-icons more_pos"
              *ngIf="vendorInvoiceAccess && serviceInvoiceAccess && isOpen"
            >
              expand_less
            </span>
          </div>
          <div
            [ngStyle]="{ display: isOpen ? 'block' : 'none' }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'ExceptionManagement'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('vendor', 'exc')"
              >Vendor</a
            >
            <a
              [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('service', 'exc')"
              >Service</a
            >
            <a
              [routerLink]="'ExceptionManagement/approvalPending'"
              routerLinkActive="active_sub_cls"
              *ngIf="financeapproveDisplayBoolean"
              (click)="exceptionMenu('service', 'exc')"
              >Approval Pending</a
            >
          </div>
        </div>
        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'Create_GRN_inv_list'"
          routerLinkActive="active_cls"
          *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess"
          (click)="navClick()"
        >
          Create GRN
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]=" vendorInvoiceAccess ? 'invoice/allInvoices':'invoice/ServiceInvoices'"
          (click)="doc_status_route()"
          *ngIf="show_document_status"
          [ngClass]="{
            active_cls: router.url.includes('invoice'),
            cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          }"
        >
          Document Status
        </div>
        <div
          style="position: relative"
          *ngIf="
            financeapproveDisplayBoolean &&
            vendorInvoiceAccess &&
            approveBoolean
          "
        >
          <div
            class="nav_Link f-14"
            (click)="approvalDropdown()"
            [ngClass]="{
              active_cls: router.url.includes('approvals'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
          >
            Approvals
            <span
              class="material-icons more_pos"
              *ngIf="
                vendorInvoiceAccess &&
                serviceInvoiceAccess &&
                !isOpen_apr &&
                serviceApprovalsEnabled
              "
            >
              expand_more
            </span>
            <span
              class="material-icons more_pos"
              *ngIf="
                vendorInvoiceAccess &&
                serviceInvoiceAccess &&
                isOpen_apr &&
                serviceApprovalsEnabled
              "
            >
              expand_less
            </span>
          </div>
          <div
            [ngStyle]="{ display: isOpen_apr ? 'block' : 'none' }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'approvals'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('vendor', 'apr')"
              >Vendor</a
            >
            <a
              [routerLink]="'approvals/ServiceInvoices'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('service', 'apr')"
              >Service</a
            >
          </div>
        </div>

        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'GRN_approvals'"
          routerLinkActive="active_cls"
          *ngIf="GRNApprovalAccess"
          (click)="navClick()"
        >
          GRN Approvals
        </div>
        <div
          style="position: relative"
          (clickOutside)="onClickedOutside($event, 'more')"
        >
          <div
            class="nav_Link f-14"
            (click)="more_routes()"
            [ngClass]="{
              active_cls:
                router.url.includes('/customer/vendor/') ||
                router.url.includes('serviceProvider') ||
                router.url.includes('documentSummary') ||
                router.url.includes('roles') ||
                router.url.includes('service_batch_trigger') ||
                router.url.includes('bulkUploadService') ||
                router.url.includes('ERP_approvals') ||
                router.url.includes('settings'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
          >
            {{ more_text }}
            <span class="material-icons more_pos">
              {{ more_icon }}
            </span>
          </div>
          <div
            [ngStyle]="{
              display: more_icon == 'expand_less' ? 'block' : 'none'
            }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'documentSummary'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Summary')"
              >Summary</a
            >
            <a
              [routerLink]="supplier_route"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange(supplier_names)"
              *ngIf="vendor_SP_PageAccess"
              >{{ supplier_names }}</a
            >
            <a
              [routerLink]="'roles'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Roles')"
              *ngIf="addUsersBoolean"
              >Roles</a
            >
            <!-- <a
              [routerLink]="'ERP_approvals'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('ERP Approvals')"
              *ngIf="addUsersBoolean"
              >ERP Approvals</a
            > -->
            <a
              [routerLink]="'bulkUploadService'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Service Bulk upload/ Batch trigger')"
              *ngIf="serviceInvoiceAccess && serviceTriggerBoolean"
              >Service Bulk upload/ Batch trigger</a
            >
            <a
              [routerLink]="'settings'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Settings')"
              >Settings</a
            >
            <!-- <a
              [routerLink]="'service_batch_trigger'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Service batch trigger')"
              *ngIf="serviceInvoiceAccess"
              >Service batch trigger</a
            > -->
          </div>
        </div>

        <!-- <div >
          <div
          class="nav_Link f-14"
          [routerLink]="'vendor'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Vendors
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'serviceProvider'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Service Providers
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'roles'"
          routerLinkActive="active_cls"
          *ngIf="addUsersBoolean"
        >
          Roles
        </div>
        </div> -->
      </div>
    </div>

    <div
      class="d-flex align-items-center"
      [ngStyle]="{ marginRight: !isDesktop ? '40px' : '' }"
    >
      <div
        class="user_d f-13"
        *ngIf="
          (router.url.includes('ExceptionManagement') ||
            router.url.includes('invoice') ||
            router.url.includes('documentSummary') ||
            router.url.includes('GRN_approvals') ||
            router.url.includes('approvals') ||
            router.url.includes('bulkUploadService') ||
            router.url.includes('Create_GRN_inv_list')) &&
          isDesktop
        "
        [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
      >
        Card
        <span class="portal">
          <label class="switch">
            <input
              type="checkbox"
              [checked]="isTableView"
              (change)="onChangeUI($event.target.checked)"
            />
            <span class="slider round"></span>
          </label>
        </span>
        Table
      </div>
      <div class="short_name f-12">{{ name_short }}</div>
      <div
        class="f-12 mr-1"
        [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            ? 'cenomiNavlink'
            : ''
        "
        *ngIf="!isMobile"
      >
        {{ userDetails.userdetails.firstName }}
      </div>
      <div (clickOutside)="onClickedOutside($event, 'logout')" *ngIf="!isMobile">
        <!-- <mat-icon
          class="sign_out dropdown"
          svgIcon="logout"
          title="Sign Out"
          (click)="isActive()"
        ></mat-icon> -->
        <span class="material-icons sign_out dropdown"  
          title="Sign Out"
          (click)="isActive()">
          logout
          </span>
        <div
          [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
          class="dropdown-content"
        >
          <a (click)="openDialog()">Change Password</a>
          <a routerLink="profile">Profile</a>
          <a (click)="confirm_pop()">Logout</a>
        </div>
      </div>
    </div>
    <div *ngIf="!isDesktop" class="menuIcon" (click)="openMenu()">
      <span class="material-icons">
        {{ isMenuOpen ? "close" : "menu" }}
      </span>
    </div>
  </nav>
  <div *ngIf="isMenuOpen" class="mobile_menu">
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'dashboard'"
      routerLinkActive="active_cls"
    >
      Dashboard
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'uploadInvoices'"
      routerLinkActive="active_cls"
      *ngIf="uploadPermissionBoolean"
    >
      Upload
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="{
        active_cls: router.url.includes('ExceptionManagement'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      (click)="exceptionDrop()"
      [routerLink]="vendorInvoiceAccess && !serviceInvoiceAccess ? 'ExceptionManagement' : ((!vendorInvoiceAccess && serviceInvoiceAccess) ? 'ExceptionManagement/Service_ExceptionManagement':null)"
      *ngIf="excpetionPageAccess"
    >
      <details *ngIf="vendorInvoiceAccess && serviceInvoiceAccess">
        <summary>Exceptions</summary>
        <div class="d-flex flex-column">
          <a
            [routerLink]="'ExceptionManagement'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('vendor', 'exc')"
            >Vendor</a
          >
          <a
            [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'exc')"
            >Service</a
          >
          <a
            [routerLink]="'ExceptionManagement/approvalPending'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'exc')"
            >Approval Pending</a
          >
        </div>
      </details>

      <span *ngIf="!(vendorInvoiceAccess && serviceInvoiceAccess)"
        >Exceptions</span
      >
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'Create_GRN_inv_list'"
      routerLinkActive="active_cls"
      *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess"
    >
      Create GRN
    </div>
    <div
      class="nav_Link f-14"
      [routerLink]=" vendorInvoiceAccess ? 'invoice/allInvoices':'invoice/ServiceInvoices'"
      (click)="doc_status_route()"
      [ngClass]="{
        active_cls: router.url.includes('invoice'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      routerLinkActive="active_cls"
      *ngIf="show_document_status"
    >
      Document Status
    </div>
    <div
      class="nav_Link f-14"
      (click)="approvalDropdown()"
      [ngClass]="{
        active_cls: router.url.includes('approvals'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      *ngIf="
        financeapproveDisplayBoolean && vendorInvoiceAccess && approveBoolean
      "
    >
      <span
        *ngIf="
          !(
            vendorInvoiceAccess &&
            serviceInvoiceAccess &&
            serviceApprovalsEnabled
          )
        "
        >Approvals</span
      >
      <details
        *ngIf="
          vendorInvoiceAccess && serviceInvoiceAccess && serviceApprovalsEnabled
        "
      >
        <summary>Approvals</summary>
        <div class="d-flex flex-column">
          <a
            [routerLink]="'approvals'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('vendor', 'apr')"
            >Vendor</a
          >
          <a
            [routerLink]="'approvals/ServiceInvoices'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'apr')"
            >Service</a
          >
        </div>
      </details>
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'GRN_approvals'"
      routerLinkActive="active_cls"
      *ngIf="GRNApprovalAccess"
    >
      GRN Approvals
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'documentSummary'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Summary')"
    >
      Summary
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="supplier_route"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange(supplier_names)"
      *ngIf="vendor_SP_PageAccess"
    >
      {{ supplier_names }}
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'roles'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Roles')"
      *ngIf="addUsersBoolean"
    >
      Roles
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'ERP_approvals'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('ERP Approvals')"
      *ngIf="addUsersBoolean"
    >
      ERP Approvals
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'bulkUploadService'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Service Bulk upload/ Batch trigger')"
      *ngIf="serviceInvoiceAccess"
    >
      Service Bulk upload/ Batch trigger
    </div>
    
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      (click)="openDialog()"
      *ngIf="isMobile"
    >
      Change Password
    </div>
    <!-- <a routerLink="profile">Profile</a> -->

    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      (click)="confirm_pop()"
      *ngIf="isMobile"
    >
      Logout
    </div>
  </div>

  <section id="body_content">
    <router-outlet></router-outlet>
  </section>

  <footer class="f-12">
    <!-- Copyrights@2024 -->
    <div>
      <a href="static-content/DPA" target="_blank">{{isMobile? 'DPA':'Data Processing Agreement (DPA)'}}</a> | 
      <a href="static-content/privacy-policy" target="_blank">Privacy policy</a> | 
      <a href="mailto:help.serina@datasemantics.co">Help</a>
    </div>
  </footer>
</div>

<!-- Cnfirmation Dialog -->
<!-- <p-dialog
  header=" "
  [(visible)]="displayResponsivepopup"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="text-center">
    <div>
      <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i>
    </div>
    <div class="deleteDivText">
      {{ BtnText }}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">
        Yes
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        (click)="displayResponsivepopup = false"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<p-toast></p-toast>
